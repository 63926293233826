var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.postDetail.title}},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.postData,"fields":_vm.fields,"busy":_vm.isLoading,"show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((_vm.currentPage - 1) * 10 + index + 1)+" ")]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).locale("id").format("DD MMMM YYYY"))+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status === 'publish')?_c('b-badge',{attrs:{"variant":"light-success"}},[_vm._v(" Publish ")]):_c('b-badge',{attrs:{"variant":"light-warning"}},[_vm._v(" Waiting Approval ")])]}},{key:"cell(category)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.category ? item.category.name : "-")+" ")]}},{key:"cell(creator)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.creator ? item.creator.name : "-")+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"danger","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Action ")]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":'/edit-post/' + item.uuid}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"EditIcon"}}),_vm._v(" Update ")],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteItem(item.uuid)}}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"TrashIcon"}}),_vm._v(" Delete ")],1)],1)]}}])}),(_vm.result.itemsPerPage > 0)?_c('div',{staticClass:"mt-3 d-flex justify-content-between align-items-center"},[_c('small',[_vm._v("Showing "+_vm._s((_vm.result.currentPage - 1) * 10 + 1)+" to "+_vm._s((_vm.result.currentPage - 1) * 10 + 1 * _vm.result.itemCount)+" from "+_vm._s(_vm.result.totalItems))]),_c('b-pagination',{staticClass:"justify-content-end",attrs:{"total-rows":_vm.result.totalItems,"per-page":_vm.result.itemsPerPage,"aria-controls":"my-table"},on:{"change":_vm.getData},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e(),_c('b-col',{staticClass:"p-0 mt-2",attrs:{"cols":"12"}},[_c('a',{staticClass:"btn waves-effect waves-float waves-light btn-secondary",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.handleBackButton()}}},[_vm._v(" Back ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }