<template>
  <b-card :title="postDetail.title">
    <b-table
      striped
      hover
      :items="postData"
      :fields="fields"
      :busy="isLoading"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPage - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(created_at)="{ item }">
        {{ moment(item.created_at).locale("id").format("DD MMMM YYYY") }}
      </template>

      <template v-slot:cell(status)="{ item }">
        <b-badge variant="light-success" v-if="item.status === 'publish'">
          Publish
        </b-badge>
        <b-badge variant="light-warning" v-else> Waiting Approval </b-badge>
      </template>

      <template v-slot:cell(category)="{ item }">
        {{ item.category ? item.category.name : "-" }}
      </template>

      <template v-slot:cell(creator)="{ item }">
        {{ item.creator ? item.creator.name : "-" }}
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>

          <b-dropdown-item :to="'/edit-post/' + item.uuid">
            <feather-icon icon="EditIcon" class="mr-75" />
            Update
          </b-dropdown-item>

          <b-dropdown-item @click="deleteItem(item.uuid)">
            <feather-icon icon="TrashIcon" class="mr-75" />
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <div
      v-if="result.itemsPerPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (result.currentPage - 1) * 10 + 1 }} to
        {{ (result.currentPage - 1) * 10 + 1 * result.itemCount }} from
        {{ result.totalItems }}</small
      >
      <b-pagination
        class="justify-content-end"
        v-model="currentPage"
        :total-rows="result.totalItems"
        :per-page="result.itemsPerPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>

    <b-col cols="12" class="p-0 mt-2">
      <a
        @click="handleBackButton()"
        variant="secondary"
        class="btn waves-effect waves-float waves-light btn-secondary"
      >
        Back
      </a>
    </b-col>
  </b-card>
</template>

<script>
import moment from "moment";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BTable,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BBadge,
  BSpinner,
  BPagination,
  BDropdown,
  BDropdownItem,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    BTable,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BBadge,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      moment,
      currentPage: 1,
      isLoading: false,
      result: [],
      postData: [],
      postDetail: {},
      fields: [
        { key: "no" },
        { key: "created_at", label: "Tanggal Submit" },
        { key: "title", label: "Judul" },
        { key: "seo_title", label: "SEO Title" },
        { key: "status" },
        { key: "category", label: "Kategori" },
        { key: "creator" },
        { key: "actions" },
      ],
      filter: {},
      validations: "",
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleBackButton() {
      history.back();
    },
    getData(page) {
      this.isLoading = true;
      const params = this.$route.params.uuid;
      this.currentPage = page;
      const queryParams = this.filter;
      queryParams.page = page;
      this.$http
        .get(`/api/v1/admin/posts/list?competition_uuid=${params}`, {
          params: queryParams,
        })
        .then((response) => {
          this.result = response.data.data.meta;
          this.postData = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.getDetail();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDetail() {
      const params = this.$route.params.uuid;
      this.$http
        .get("/api/v1/admin/competitions/" + params)
        .then((response) => {
          this.postDetail = response.data.data;
        });
    },
    deleteItem(slug) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this Post?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .delete(`/api/v1/admin/posts/remove/${slug}`)
            .then((response) => {
              this.getData(this.currentPage);
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Post successfully deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
